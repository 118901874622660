import axios from 'axios';

const productStore = {
	namespaced: true,
	state() {
		'use strict';
		return {
			shippingInformation: {},
            loading: false,
            message: ''
		};
	},
	getters: {
		getShippingInformation(state) {
			'use strict';
			return state.shippingInformation;
		},
        getRuntimeConfig(_state, _, rootState) {
            'use strict';
			return rootState.common.runtimeConfig;
		},
        getLoading(state) {
			'use strict';
			return state.loading;
		},
        getMessage(state) {
            'use strict';
			return state.message;
        }
	},
	actions: {
         /* jshint strict: false */
		setShippingInformation({ commit, state, getters }, req) {
            commit('setLoading', true);
            commit('setMessage','');
			return axios
				.post(getters.getRuntimeConfig.Product.Api.GetShippingInformation + req.code, {
					zipCode: req.zipCode,
					city: req.city,
					state: req.state
				})
				.then((response) => {
					commit('setShippingInformationData', response.data.data);
				}).catch((error) => {
                    console.log( error.response.data.messages[0]);
                    commit('setLoading', false);
                    commit('setShippingInformationData', error.response.data.data);
                    commit('setMessage', error.response.data.messages[0]);
                });
		}
	},
	mutations: {
        setShippingInformationData(state, shippingInformation) {
			'use strict';
			state.shippingInformation = shippingInformation;
            state.loading = false;
		},
        setLoading(state, value) {
			'use strict';
			state.loading = value;
		},
        setMessage(state, value) {
			'use strict';
			state.message = value;
		}
	}
};

export default productStore;