import Vue from 'vue';
import axios from 'axios';
import AdyenConfig from '../adyen-configuration.js';
import { trackKlaviyoIdentity } from '../../../objects/track/klaviyo.track.js';

var dropin = null;

const checkoutStore = {
	namespaced: true,
	state() {
		'use strict';
		return {
			cart: {},
			shippingOptions: [],
			runtimeConfig: {},
			translations: {},
			language: '',
			svensktTennInvoice: false,
			editingAddress: {},
			giftCardMessages: {}
		};
	},
	getters: {
		getCart(state) {
			'use strict';
			return state.cart;
		},
		getRuntimeConfig(state) {
			'use strict';
			return state.runtimeConfig;
		},
		getTranslations(state) {
			'use strict';
			return state.translations;
		},
		getEditingAnyAddress(state) {
			'use strict';
			return (state.editingAddress) ? Object.values(state.editingAddress).includes('yes') : false;
		},
		getEditingBillingAddress(state) {
			'use strict';
			return state.editingAddress['billing-address'] === 'yes';
		},
		getSvensktTennInvoice(state) {
			'use strict';
			return state.svensktTennInvoice;
		},
		getGiftcardMessages(state) {
			'use strict';
			return state.giftCardMessages;
		}
	},
	actions: {
		/* jshint strict: false */
		getCart({ commit, state, dispatch }) {
			axios
				.get(state.runtimeConfig.Checkout.Api.GetCart + (Object.keys(state.cart).length === 0 ? '?init=true' : ''))
				.then((response) => {
					commit('setCart', response.data);
					dispatch('getPaymentMethods');
				})
				.catch((error) => {
					console.log('Error getting cart', error);
				});
		},
		/* jshint strict: false */
		setShippingAddress({ commit, state, dispatch }, payload) {
			return axios
				.post(state.runtimeConfig.Checkout.Api.SetShippingAddress, payload)
				.then((response) => {
					commit('setCart', response.data);
					dispatch('getPaymentMethods');
					return response.data;
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data;
					}
				});
		},
		/* jshint strict: false */
		setBillingAddress({ commit, state, dispatch }, payload) {
			return axios
				.post(state.runtimeConfig.Checkout.Api.SetBillingAddress, payload)
				.then((response) => {
					commit('setCart', response.data);
					dispatch('getPaymentMethods');
					if (response.data && response.data.data.klaviyoIdentityEmail) {
						trackKlaviyoIdentity(response.data.klaviyoIdentityEmail);
					}
					return response.data;
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data;
					}
				});
		},
		setB2BCustomerDetails({ commit, state, dispatch }, payload) {
			return axios
				.post(state.runtimeConfig.Checkout.Api.SetB2BCustomerDetails, payload)
				.then((response) => {
					commit('setCart', response.data);
					dispatch('getPaymentMethods');
					return response.data;
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data;
					}
				});
		},
		/* jshint strict: false */
		setInvoiceDetails({ commit, state, dispatch }, payload) {
			return axios
				.post(state.runtimeConfig.Checkout.Api.SetInvoiceDetails, payload)
				.then((response) => {
					commit('setInvoiceDetails', payload);
					commit('setCart', response.data);
					return response.data;
				})
				.catch((error) => {
					if (error.response.status === 400) {
						return error.response.data;
					}
				});
		},
		/* jshint strict: false */
		setBillingAddressFromSsn({ commit, state, dispatch }, payload) {
			return axios
				.post(state.runtimeConfig.Checkout.Api.UpdateAddressBySocialSecurityNo, {
					socialSecurityNo: payload
				})
				.then((response) => {
					commit('setCart', response.data);
					dispatch('getPaymentMethods');
				});
		},
		/* jshint strict: false */
		setUseOtherDeliveryAddress({ commit, state, dispatch }, payload) {
			axios
				.post(state.runtimeConfig.Checkout.Api.OtherDeliveryAddress, {
					otherDeliveryAddress: payload
				})
				.then((response) => {
					commit('setCart', response.data);
					dispatch('getPaymentMethods');
				})
				.catch((error) => {
					console.log(error);
				});
		},
		/* jshint strict: false */
		setShippingOption({ commit, state, dispatch }, payload) {
			axios
				.post(state.runtimeConfig.Checkout.Api.SetShippingOption, payload)
				.then((response) => {
					commit('setCart', response.data);
					dispatch('getPaymentMethods');
				})
				.catch((error) => {
					console.log(error);
				});
		},
		/* jshint strict: false */
		updateGiftWrap({ commit }, data) {
			commit('updateGiftWrap', data);
		},
		/* jshint strict: false */
		addGiftcard({ commit, state, dispatch }, payload) {
			axios
				.post(state.runtimeConfig.Checkout.Api.AddGiftcard, payload)
				.then((response) => {
					commit('setCart', response.data);
					commit('setGiftcardMessages', []);
					dispatch('getPaymentMethods');
				})
				.catch((error) => {
					commit('setGiftcardMessages',error.response.data.errors);
				});
		},
		/* jshint strict: false */
		removeGiftcard({ commit, state, dispatch }, id) {
			axios
				.delete(state.runtimeConfig.Checkout.Api.RemoveGiftcard + '/' + id)
				.then((response) => {
					commit('setCart', response.data);
					dispatch('getPaymentMethods');
				})
				.catch((error) => {
					console.log(error);
				});
		},
		/* jshint strict: false */
		getTranslations({ commit, state }) {
			axios
				.get(state.runtimeConfig.Translations)
				.then((response) => {
					commit('setTranslations', response.data.data);
				})
				.catch((error) => {
					console.log('Error getting translations', error.response.data);
				});
		},
		/* jshint strict: false */
		setCreateAccount({ commit, state }, payload) {
			axios
				.post(state.runtimeConfig.Checkout.Api.SetCreateAccount, payload)
				.then((response) => {
					commit('setCart', response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		/* jshint strict: false */
		getPaymentMethods({ commit, state }) {
			if ((dropin !== null)) {
				dropin.unmount();
			}

			if (state.cart.data.showPaymentMethods && state.cart.data.isValid) {
				axios
					.get(state.runtimeConfig.Checkout.Api.PaymentMethods)
					.then(async (response) => {
						var adyenConfig = new AdyenConfig({
							paymentMethodsResponse: response.data,
							clientKey: state.runtimeConfig.Checkout.Key,
							locale: state.cart.data.locale,
							environment: state.runtimeConfig.Checkout.Environment,
							payments: state.runtimeConfig.Checkout.Api.Payments,
							paymentsDetails: state.runtimeConfig.Checkout.Api.PaymentsDetails,
							currencyCode: state.cart.data.price.currencyCode,
							amount: state.cart.data.price.totalToPay
						});

						var checkout = await new AdyenCheckout(adyenConfig); // jshint ignore:line
						dropin = checkout.create('dropin', {
							onSelect: () => {
								if (state.svensktTennInvoice) {
									axios
										.post(state.runtimeConfig.Checkout.Api.SetSvensktTennInvoice, { selected: false })
										.then((response) => {
											commit('setCart', response.data);
										})
										.catch((error) => {
											console.log(error);
										});
								}
								commit('setSvensktTennInvoice', false);
							},
							onReady: () => {
								if (state.cart.data.svensktTennInvoiceSelected) {
									commit('setSvensktTennInvoice', true);
									dropin.closeActivePaymentMethod();
								}
							}
						})
							.mount('#adyen-checkout__dropin-container');
					});
			}
			else if (state.cart.data.showSvensktTennInvoice && state.cart.data.svensktTennInvoiceSelected) {
				commit('setSvensktTennInvoice', true);
			}
		},
		/* jshint strict: false */
		setEditingAddress({ commit }, editingAddress) {
			commit('setEditingAddress', editingAddress);
		},
		/* jshint strict: false */
		setSvensktTennInvoice({ commit, state }) {
			commit('setSvensktTennInvoice', true);
			if (dropin !== null) {
				dropin.closeActivePaymentMethod();
			}
			axios
				.post(state.runtimeConfig.Checkout.Api.SetSvensktTennInvoice, { selected: true })
				.then((response) => {
					commit('setCart', response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	},
	mutations: {
		updateGiftWrap(state, payload) {
			'use strict';
			/* jshint ignore:start */
			const url =	state.runtimeConfig.Cart.Api.GetCart  +	payload.sku + '/giftwraps/';
			/* jshint ignore:end */
			delete payload.sku;
		},
		setCart(state, cart) {
			'use strict';
			state.cart = cart;
		},
		setInvoiceDetails(state, invoiceDetails) {
			'use strict';
			state.cart.invoiceDetails = invoiceDetails;
		},
		setShippingOptions(state, shippingOptions) {
			'use strict';
			state.shippingOptions = shippingOptions;
		},
		setRuntimeConfig(state, runtimeConfig) {
			'use strict';
			axios.defaults.baseURL = runtimeConfig.VUE_APP_BASE_URL;
			state.runtimeConfig = runtimeConfig;
		},
		setLanguage(state, language) {
			'use strict';
			state.language = language;
		},
		setTranslations(state, translations) {
			'use strict';
			state.translations = translations;
		},
		setEditingAddress(state, editingAddress) {
			'use strict';
			Vue.set(state.editingAddress, editingAddress.address, editingAddress.editing);
		},
		setSvensktTennInvoice(state, svensktTennInvoice) {
			'use strict';
			state.svensktTennInvoice = svensktTennInvoice;
		},
		setGiftcardMessages(state, messages) {
			'use strict';
			state.giftCardMessages = messages;
		}
	}
};

export default checkoutStore;
