import axios from 'axios';
import { trackCartUpdate } from '../../../objects/track/cart.track.js';
import { trackKlaviyoAddToCart } from '../../../objects/track/klaviyo.track.js';

const cartStore = {
	namespaced: true,
	state() {
		'use strict';
		return {
			cart: {
				lines: [],
				price: []
			},
			recommendations: [],
			promo: {},
			emailedCart: {
				lines: []
			},
			tracking: {},
			showCart: false,
			loading: false,
			apiError: false,
			errorLog: []
		};
	},
	getters: {
		getCart(state) {
			'use strict';
			return state.cart;
		},
		getRecommendations(state) {
			'use strict';
			return state.recommendations;
		},
		getPromo(state) {
			'use strict';
			return state.promo;
		},
		getTotalQuantity(state) {
			'use strict';
			return state.cart.lines.reduce((total, item) => (item.quantityStep && item.quantityStep !== 1) ? total + 1 : total + item.quantity, 0);
		},
		/* jshint strict: false */
		getQuantity: (state) => (code) => {
			let item = state.cart.lines.find(item => item.code === code);
			return (item) ? parseFloat(item.quantity) : 0;
		},
		maxQuantityReached: (state) => (code) => {
			let item = state.cart.lines.find(item => item.code === code);
			return item && item.maxQuantity > 0 && item.quantity >= item.maxQuantity;
		},
		getLoading(state) {
			'use strict';
			return state.loading;
		},
		getRuntimeConfig(_state, _, rootState) {
			return rootState.common.runtimeConfig;
		},
		getEmailedCart(state) {
			'use strict';
			return state.emailedCart;
		},
		hasTooManyInEmailedCart(state, getters) {
			return state.emailedCart.lines.some(item => item.maxQuantity > 0 && item.quantity + getters.getQuantity(item.code) > item.maxQuantity);
		},
		showCart(state) {
			return state.showCart;
		},
		getGiftWrap: (state) => (code) => {
			let item = state.cart.lines.find(item => item.code === code);
			return item.giftWrapping;
		},
		hasGiftWrapping(state) {
			return state.cart.lines.some(item => item.giftWrapping.noGiftWrapping === false);
		},
		hasApiError(state) {
			return state.apiError;
		}
	},
	actions: {
		/* jshint strict: false */
		addToCart({ commit, dispatch, state, getters }, item) {
			try {
				let existingItem = state.cart.lines.find(lineItem => lineItem.code === item.code);
				if (existingItem) {
					let newQuantity = (parseFloat(existingItem.quantity) * 10 + parseFloat(item.quantity) * 10) / 10; // hack due to floating point precision issues
					dispatch('updateQuantity', { code: item.code, quantity: newQuantity });
				} else {
					window.dispatchEvent(new CustomEvent('vueCartChange'));
					axios
						.post(getters.getRuntimeConfig.Cart.Api.AddToCart, { code: item.code, quantity: parseFloat(item.quantity) })
						.then((response) => {
							commit('setCart', response.data);
							trackCartUpdate(response.data.data.tracking);
							trackKlaviyoAddToCart(response.data.data.tracking);
						})
						.catch((error) => {
							commit('logError', { msg: 'Error: addToCart', error });
						});
				}
			} catch (error) {
				commit('logError', { msg: 'Error: addToCart', error });
			}
		},
		/* jshint strict: false */
		addEmailedToCart({ commit, state, getters }) {
			window.dispatchEvent(new CustomEvent('vueCartChange'));
			let items = state.emailedCart.lines.filter(item => item.stockStatus === 1).map(item => ({ code: item.code, quantity: item.quantity }));
			axios
				.post(getters.getRuntimeConfig.Cart.Api.AddManyToCart, items)
				.then((response) => {
					commit('setCart', response.data);
					trackKlaviyoAddToCart(response.data.data.tracking);
				})
				.catch((error) => {
					commit('logError', { msg: 'Error: addEmailedToCart', error });
				});

		},
		/* jshint strict: false */
		deleteFromCart({ commit, getters }, code) {
			window.dispatchEvent(new CustomEvent('vueCartChange'));
			if (!code) {
				return;
			}
			axios
				.delete(getters.getRuntimeConfig.Cart.Api.DeleteCartItem + '/' + code)
				.then((response) => {
					commit('setCart', response.data);
					trackCartUpdate(response.data.data.tracking);
				})
				.catch((error) => {
					commit('logError', { msg: 'Error: deleteFromCart', error });
				});
		},
		updateEmailedItem({ commit, getters }, item) {
			commit('updateEmailedItem', item);
		},
		deleteEmailedItem({ commit }, code) {
			commit('deleteEmailedItem', code);
		},
		updateQuantity({ commit, getters }, item) {
			commit('setLoading', true);
			window.dispatchEvent(new CustomEvent('vueCartChange'));
			axios
				.put(getters.getRuntimeConfig.Cart.Api.UpdateCartItem, item)
				.then((response) => {
					commit('setCart', response.data);
					trackCartUpdate(response.data.data.tracking);
					trackKlaviyoAddToCart(response.data.data.tracking);
				})
				.catch((error) => {
					commit('logError', { msg: 'Error: updateQuantity', error });
				});
		},
		/* jshint strict: false */
		getCart({ commit, getters }) {
			axios
				.get(getters.getRuntimeConfig.Cart.Api.GetCart)
				.then((response) => {
					commit('setCart', response.data);
				})
				.catch((error) => {
					commit('logError', { msg: 'Error getting cart', error });
				});
		},
		/* jshint strict: false */
		getEmailedCart({ commit, getters }, cart) {
			axios
				.get(getters.getRuntimeConfig.Cart.Api.GetEmailedCart + '/?cart=' + cart)
				.then((response) => {
					commit('setEmailedCart', response.data);
				})
				.catch((error) => {
					commit('logError', { msg: 'Error getting cart', error });
				});
		},
		/* jshint strict: false */
		setShowCart({ commit }) {
			commit('setShowCart');
		},
		setGiftWrap({ commit, getters }, data) {
			axios
				.put(getters.getRuntimeConfig.Cart.Api.GetCart + '/' + data.code + '/giftwraps/', { allInSame: data.allInSame, giftWraps: data.giftWraps })

				.then((response) => {
					commit('setCart', response.data);
				})
				.catch((error) => {
					commit('logError', { msg: 'Error: setGiftWrap', error });
				});
		},
		deleteGiftWraps({ commit, getters }, code) {
			axios
				.put(getters.getRuntimeConfig.Cart.Api.GetCart + '/' + code + '/giftwraps/', { giftWraps: [] })

				.then((response) => {
					commit('setCart', response.data);
				})
				.catch((error) => {
					commit('logError', { msg: 'Error: deleteGiftWraps', error });
				});
		},
		addGiftcard({ commit, getters }, data) {
			axios
				.post(getters.getRuntimeConfig.Cart.Api.AddGiftcard, data)
				.then((response) => {
					commit('setCart', response.data);
					trackCartUpdate(response.data.data.tracking);

				})
				.catch((error) => {
					commit('logError', { msg: 'Error: addGiftcard', error });
				});
		}
	},

	mutations: {
		setCart(state, responseData) {
			'use strict';
			state.cart = responseData.data.cart;
			state.recommendations = responseData.data.recommendations;
			state.promo = responseData.data.promo;
			state.errors = responseData.errors;
			state.messages = responseData.messages;
			state.meta = responseData.meta;
			state.loading = false;
			state.apiError = false;
		},
		setEmailedCart(state, responseData) {
			'use strict';
			state.emailedCart = responseData.data.cart;
			state.errors = responseData.errors;
			state.messages = responseData.messages;
			state.meta = responseData.meta;
			state.loading = false;
		},
		addToCart(state, data) {
			'use strict';
			state.cart = data.data.cart;
		},
		updateEmailedItem(state, item) {
			let existingItem = state.emailedCart.lines.find(lineItem => lineItem.code === item.code);
			if (existingItem) {
				existingItem.quantity = item.quantity;
			}
		},
		deleteEmailedItem(state, code) {
			let index = state.emailedCart.lines.findIndex(item => item.code === code);
			if (index > -1) {
				state.emailedCart.lines.splice(index, 1);
			}
		},
		addManyToCart(state, data) {
			'use strict';
			state.emailedCart = data.data.emailedCart;
		},
		setShowCart(state) {
			'use strict';
			state.showCart = !state.showCart;
		},
		setLoading(state, value) {
			'use strict';
			state.loading = value;
		},
		logError(state, error) {
			'use strict';
			state.errorLog.push(error);
			state.apiError = true;
			state.loading = false;
		}

	}
};

export default cartStore;