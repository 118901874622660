import axios from 'axios';

const commonStore = {
	namespaced: true,
	state() {
		'use strict';
		return {
			runtimeConfig: {},
			translations: {}
		};
	},
	getters: {
		getRuntimeConfig(state) {
			'use strict';
			return state.runtimeConfig;
		},
		getTranslations(state) {
			'use strict';
			return state.translations;
		}
	},
	actions: {
		/* jshint strict: false */
		setRuntimeConfig({ commit }, data) {
			commit('setRuntimeConfig', data);
		},
		/* jshint strict: false */
		getTranslations({ commit, state }) {
			axios
				.get(state.runtimeConfig.Translations)
				.then((response) => {
					commit('setTranslations', response.data.data);
				})
				.catch((error) => {
					console.log('Error getting translations', error.response.data);
				});
		}
	},
	mutations: {
		setRuntimeConfig(state, runtimeConfig) {
			'use strict';
			axios.defaults.baseURL = runtimeConfig.VUE_APP_BASE_URL;
			state.runtimeConfig = runtimeConfig;
		},
		setTranslations(state, translations) {
			'use strict';
			state.translations = translations;
		}
	}
};

export default commonStore;