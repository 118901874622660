/* jshint ignore:start */

export const trackKlaviyoAddToCart = (tracking) => {
	'use strict';
	if (window.klaviyo) {
		(tracking.ecommerce.add.products).forEach(added => {
			const item = {
				"$value": added.price,
				"AddedItemProductName": added.name,
				"AddedItemProductID": added.id.replace('sku-', ''),
				"AddedItemSKU": added.id.replace('sku-', ''),
				"AddedItemCategories": added.navCategories,
				"AddedItemImageURL": added.imageUrl,
				"AddedItemURL": added.url,
				"AddedItemPrice": added.itemprice,
				"AddedItemQuantity": added.quantity,
				"ItemNames": tracking.ecommerce.cart.products.map((item) => item.name),
				"CheckoutURL": tracking.ecommerce.checkoutUrl,
				"Currency": tracking.ecommerce.currencyCode.replace('kr', 'SEK').replace('KR', 'SEK'),
				"Items": tracking.ecommerce.cart.products.map((item) => {
					return {
						"ProductID": item.id.replace('sku-', ''),
						"SKU": item.id.replace('sku-', ''),
						"ProductName": item.name,
						"Quantity": item.quantity,
						"ItemPrice": item.itemprice,
						"RowTotal": item.price,
						"ProductURL": item.url,
						"ImageURL": item.imageUrl,
						"ProductCategories": item.navCategories
					};
				  })
			};
	
			klaviyo.push(["track", "Added to Cart", item]);
		});
		
	}
};

export const trackKlaviyoIdentity = (email) => {
	'use strict';
	if (window.klaviyo) {
		klaviyo.push(['identify', { '$email' : email }]);
	}
};

/* jshint ignore:end */
