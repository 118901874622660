/* jshint ignore:start */
export default function AdyenConfig(config) {
	'use strict';
	return {
		paymentMethodsResponse: config.paymentMethodsResponse,
		clientKey: config.clientKey,
		locale: config.locale,
		environment: config.environment,
		amount: {
			currency: config.currencyCode,
			value: Math.floor(config.amount * 100)
		},
		onSubmit: (state, dropin) => {
			if (document.getElementById('serverCheckoutErrorMessage')){
				var elem = document.getElementById('serverCheckoutErrorMessage');
				elem.parentNode.removeChild(elem);
			}
			// Your function calling your server to make the `/payments` request
			makePayment(config.payments, state.data)
				.then(response => {
					if (response.action) {
						// Drop-in handles the action object from the /payments response
						dropin.handleAction(response.action);
					}
					else {
						// Your function to show the final result to the shopper
						window.location.href = response.orderConfirmationUrl;
					}
				})
				.catch(() => {
					window.location.href = response.errorUrl;
				});
		},
		onAdditionalDetails: (state, dropin) => {
			// Your function calling your server to make a `/payments/details` request
			makePayment(config.paymentsDetails, state.data)
				.then(response => {
					if (response.action) {
						// Drop-in handles the action object from the /payments/details response
						dropin.handleAction(response.action);
					}
					else {
						// Your function to show the final result to the shopper
						window.location.href = response.orderConfirmationUrl;
					}
				})
				.catch(() => {
					window.location.href = response.errorUrl;
				});
		},
		paymentMethodsConfiguration: {
			card: {
				hasHolderName: true,
				holderNameRequired: true,
				enableStoreDetails: false,
				// hideCVC: false,
				styles: {
					base: {
						fontSmoothing: 'antialiased',
						fontFamily: '"apercuregular", "Trebuchet MS", "sans-serif"',
						color: '#000',
						caretColor: '#000'
					},
					placeholder: {
						color: 'grey',
						opacity: 1,
						fontWeight: 400
					},
					error: {
						color: '#c9010f'
					}
				}
			}
		}
	};
}
/* jshint ignore:end */