import Vue from 'vue';
import store from './store.js';

var lang = document.getElementsByTagName('html')[0].getAttribute('lang').slice(0, 2);
var market = document.getElementsByTagName('html')[0].getAttribute('data-country-iso2');
if (market) {
	var config = JSON.parse(document.getElementsByTagName('html')[0].getAttribute('data-runtime-config'));

	new Vue({
		el: '.vue-app-checkout',
		store,
		components: {
			StCheckoutVue :  () => import(/* webpackChunkName: "st-checkoutvue" */'./components/checkout-vue-adyen/CheckoutVue.vue'), // jshint ignore:line
			StCheckoutCart :  () => import(/* webpackChunkName: "st-checkoutcart" */'./components/checkout-vue-adyen/_components/CheckoutCart.vue'), // jshint ignore:line
			StSystemMessage :  () => import(/* webpackChunkName: "st-systemmessage" */'./components/system-message/SystemMessage.vue'), // jshint ignore:line
		},
		created() {
			'use strict';
			
			this.$store.commit('checkout/setRuntimeConfig', config); // TODO refactor to use common?
			this.$store.commit('checkout/setLanguage', lang); // TODO refactor to use common?
		}
	});
	new Vue({
		el: '.vue-app',
		store,
		components: {
			StSplashScreen :  () => import(/* webpackChunkName: "st-splashscreen" */'./components/splash-screen/SplashScreen.vue'),  // jshint ignore:line
			StProductRecommendations :  () => import(/* webpackChunkName: "st-productrecommendations" */'./components/product-recommendations/ProductRecommendations.vue'),  // jshint ignore:line
			StWallpaperCalculator :  () => import(/* webpackChunkName: "st-wallpapercalculator" */'./components/wallpaper-calculator/WallpaperCalculator.vue'), // jshint ignore:line
			StCurtainCalculator :  () => import(/* webpackChunkName: "st-curtaincalculator" */'./components/curtain-calculator/CurtainCalculator.vue'), // jshint ignore:line
			StAddToCart :  () => import(/* webpackChunkName: "st-addtocart" */'./components/cart-vue/molecules/add-to-cart/AddToCart.vue'), // jshint ignore:line
			StQtyInput :  () => import(/* webpackChunkName: "st-qtyinput" */'./components/cart-vue/atoms/qty-input/QtyInput.vue'), // jshint ignore:line
			StCartItem :  () => import(/* webpackChunkName: "st-cartitem" */'./components/cart-vue/molecules/cart-item/CartItem.vue'), // jshint ignore:line
			StCartGiftWrappingModal :  () => import(/* webpackChunkName: "st-giftwrappingmodal" */'./components/cart-vue/organisms/cart-gift-wrapping-modal/GiftWrappingModal.vue'),  // jshint ignore:line
			StCartPromo :  () => import(/* webpackChunkName: "st-cartpromo" */'./components/cart-vue/organisms/cart-promo/CartPromo.vue'), // jshint ignore:line
			StAccordion :  () => import(/* webpackChunkName: "st-accordion" */'./components/Accordion/Accordion.vue'), // jshint ignore:line
			StCartContainer :  () => import(/* webpackChunkName: "st-cartcontainer" */'./components/cart-vue/organisms/cart-container/CartContainer.vue'), // jshint ignore:line
			StAddToCartContainer :  () => import(/* webpackChunkName: "st-addtocartcontainer" */'./components/cart-vue/organisms/add-to-cart-container/AddToCartContainer.vue'), // jshint ignore:line
			StGenericListing :  () => import(/* webpackChunkName: "st-genericlisting" */'./components/cart-vue/atoms/generic-listing/GenericListing.vue'), // jshint ignore:line
			StCartIcon :  () => import(/* webpackChunkName: "st-carticon" */'./components/cart-vue/atoms/cart-icon/CartIcon.vue'), // jshint ignore:line
			StCartIconContainer :  () => import(/* webpackChunkName: "st-carticoncontainer" */'./components/cart-vue/organisms/cart-icon-container/CartIconContainer.vue'), // jshint ignore:line
			StCartPageContainer :  () => import(/* webpackChunkName: "st-cartpagecontainer" */'./components/cart-vue/organisms/cart-page-container/CartPageContainer.vue'), // jshint ignore:line
			StUpsell :  () => import(/* webpackChunkName: "st-upsell" */'./components/upsell/Upsell.vue'), // jshint ignore:line
			StCartModal :  () => import(/* webpackChunkName: "st-cartmodal" */'./components/cart-vue/organisms/cart-modal/CartModal.vue'), // jshint ignore:line
			StEmailCartButton :  () => import(/* webpackChunkName: "st-cartbutton" */'./components/cart-vue/atoms/email-cart-button/EmailCartButton.vue'), // jshint ignore:line
			StCartCta :  () => import(/* webpackChunkName: "st-cartcta" */'./components/cart-vue/molecules/cart-cta/CartCta.vue'), // jshint ignore:line
			StEmailedCartPageContainer :  () => import(/* webpackChunkName: "st-emailedcartpagecontainer" */'./components/cart-vue/organisms/emailed-cart-page-container/EmailedCartPageContainer.vue'), // jshint ignore:line
			StProductShippingInformation :  () => import(/* webpackChunkName: "st-productshippinginformation" */'./components/product-shippinginformation/ProductShippingInformation.vue'), // jshint ignore:line
		},
		created() {
			'use strict';
			this.$store.dispatch('common/setRuntimeConfig', config).then(() => {
				this.$store.dispatch('common/getTranslations');
				this.$store.dispatch('cart/getCart');
			});

			if (typeof initiateCharpstar === 'function') {
				/* jshint ignore:start */
				initiateCharpstar();
				/* jshint ignore:end */
			}
		}
	});
}