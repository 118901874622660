import Vue from 'vue';
import Vuex from 'vuex';
import commonStore from './commonStore.js';
import cartStore from './components/cart-vue/_store/cartStore.js';
import checkoutStore from './components/checkout-vue-adyen/_store/checkoutStore.js';
import productStore from './productStore.js';

Vue.use(Vuex);
Vue.config.devtools = true;

export default new Vuex.Store({
	modules: {
		cart: cartStore,
		checkout: checkoutStore,
		common: commonStore,
		product: productStore,
	}
});
